<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.select-testing.title')"></ProfileNavbar>
    <div class="mob-menu-title">
      <router-link tag="a" to="/testing" class="mob-menu-back">
        <img src="images/profile/arrow-back.svg">
      </router-link>
      {{ $t('profile.select-testing.title') }}
    </div>
    <div class="clearfix bg-blue pt-md-4 pt-lg-0">


      <div class="testing-tab-content tab-content">
        <router-link tag="button" to="/testing" class="btn select-testing-back btn-blue">
          <img src="/images/arrow-back.png" alt="">
        </router-link>
        <div class="testing-lang-wrapper">
          <div class="testing-lang-wrapper-inner">
            <div class="testing-lang-wrapper-text">
              {{ $t('testingLang') }}
            </div>
            <el-radio-group size="medium"
                v-model="account.education.test_language"
                @change="update()"
                :placeholder="$t('references.test-language-label')">
              <el-radio-button
                  v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }]"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-radio-button>
            </el-radio-group>
          </div>
          <img src="/images/Qabilet-Final-logo.svg" alt="" height="54px" style="margin:auto 0">

        </div>
        <div class="online-type profile-cards">
          <TestCard v-for="quizz in quizzes" :key="quizz.id" :quiz="quizz" :test_lang="testLang"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import moment from 'moment';
import VueMoment from "vue-moment";
import TestCard from "@/components/TestCard"

export default {
  name: 'SelectTesting',
  components: {
    ProfileNavbar,
    TestCard
  },
  data() {
    return {
      account: {
        education: {
          test_language: ''
        }
      },
      quizzes: [],
      user_id: 0,
      testLang: '',
      loading: true,
      hasAvailableQuizzes: false
    }
  },
  methods: {
    update() {
      if (this.account.education.test_language == this.$t('references.language-ru')) {
        this.account.education.test_language = 'ru'
      } else if (this.account.education.test_language == this.$t('references.language-kz')) {
        this.account.education.test_language = 'kz'
      }
      if (this.account.email == null) {
        this.account.email = ''
      }
      this.$http.post(window.API_ROOT + '/api/user/profile/update?account[iin]=' + this.account.iin + '&account[last_name]=' + this.account.last_name + '&account[first_name]=' + this.account.first_name + '&account[patronymic]=' + this.account.patronymic + '&account[birth_date]=' + this.account.birth_date + '&account[phone]=' + this.account.phone + '&account[email]=' + this.account.email + '&account[sex]=' + this.account.sex + '&education[region_id]=' + this.account.education.region_id + '&education[locality_id]=' + this.account.education.locality_id + '&education[city_id]=' + this.account.education.city_id + '&education[school_id]=' + this.account.education.school_id + '&education[class]=' + this.account.education.class + '&education[instruction_language]=' + this.account.education.instruction_language + '&education[test_language]=' + this.account.education.test_language + '&parent[last_name]=' + this.account.parent.last_name + '&parent[first_name]=' + this.account.parent.first_name + '&parent[patronymic]=' + this.account.parent.patronymic + '&parent[phone]=' + this.account.parent.phone)
          .then((res) => {
            this.send = false
            Vue.toastr({
              message: this.$t('profile.settings.success') + '!',
              description: this.$t('profile.settings.success'),
              type: 'success'
            });
            this.$http.get(window.API_ROOT + '/api/user/edit')
                .then((res) => {
                  localStorage.setItem('user', JSON.stringify(res));
                  this.$router.go()
                })
          }).catch((e) => {

      });
    }
  },
  beforeCreate: function () {
    if (localStorage.getItem('locale')) {
      this.testLang = localStorage.getItem('locale')
    }
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.user_id = user.id || 0;
    }
    this.$http.get(API_ROOT + '/api/olympiads').then((res) => {
      if (res.body.data) {
        this.loading = false;
        this.quizzes = res.body.data
        // for (let i = 0; i < this.quizzes.length; i++) {
        //     const quiz = this.quizzes[i];
        //     if (quiz.disabled === false) {
        //         this.hasAvailableQuizzes = true
        //         break;
        //     }
        // }
      }
    }).catch((e) => {
      this.loading = false;
      Vue.toastr({
        message: 'Error',
        description: `${e.body}`,
        type: 'error'
      })
    });
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.account = res.body.data
          if (this.account.education.test_language == 'ru') {
            this.account.education.test_language = this.$t('references.language-ru')
          } else if (this.account.education.test_language == 'kz') {
            this.account.education.test_language = this.$t('references.language-kz')
          }
          this.filled = res.data.data.filled;
          if (this.filled != true) {
            Vue.toastr({
              message: this.$t('profile.testing.warning'),
              description: this.$t('main-page.profile-filled'),
              type: 'warning'
            });
            this.$router.push('/profile')
          }
        })
        .catch((e) => {

        });

    if (localStorage.getItem('user')) {
      const {test_language} = JSON.parse(localStorage.getItem('user'))
      this.account = JSON.parse(localStorage.getItem('user')).body.data
      this.testLang = test_language ?? 'ru'
    }
    if (this.$i18n.locale === 'ru') {
      import('moment/locale/ru')
      Vue.use(VueMoment, {moment});
    }
    if (this.$i18n.locale === 'kz') {
      import('moment/locale/kk')
      Vue.use(VueMoment, {moment});
    }
  }
}
</script>
<style>
.testing-lang-wrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  margin: 1rem 23px 40px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;

}

.testing-lang-wrapper-inner {
  max-width: 60%;
}

.testing-lang-wrapper-text {
  margin-bottom: 20px;
}

@media screen and (max-width: 567px) {
  .testing-lang-wrapper-inner {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .testing-lang-wrapper img {
    margin: auto !important;
  }

  .testing-lang-wrapper .el-radio-group {
    margin: auto;
  }
}

@media screen and (max-width: 385px) {
  .testing-lang-wrapper {
    padding: 20px 10px;
  }
}
</style>




